import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './floatingMenuInforme2022.scss'
import Modal from '../../atoms/modal/modal';

interface FloatingMenuInforme2022Interface {
    buttonBg: string;
    closeIcon: string;
    openIcon: string;
    menuBg: string;
    menuTitle: string;
}

const FloatingMenuInforme2022 = (props: FloatingMenuInforme2022Interface) => {

    const data = useStaticQuery(graphql`
        query MENU_INFORME2022_F {
            allContentfulMenuCategorias(filter: {titulo: {eq: "Menu informe2022"}}) {
                nodes {
                    categorias {
                        nombreCategoria
                        slug
                        hyperLink
                        referenciaSubcategorias {
                            nombreSubcategoria
                            slug
                            link
                            verEnMenu
                            referenciaSubcategorias {
                                nombreSubcategoria
                                slug
                                link
                                verEnMenu
                            }
                        }
                    }
                    listaDerecha {
                        slug
                        hyperLink
                        nombreCategoria
                    }
                }
            }
        }
    `);

    const [showMenu, setShowMenu] = useState(false)

    const menuData = data.allContentfulMenuCategorias.nodes[0]

    return (
        <div className='o-floating-menu-2022'>
            {
                <button onClick={() => setShowMenu(true)} className='o-floating-menu-2022__btn' aria-label={showMenu ? 'Cerrar menú' : 'Abrir menú'}>
                    <img src={props.openIcon} alt="" className='o-floating-menu-2022__btn__icon'/>
                    <img loading='lazy' src={props.buttonBg} alt="" className='o-floating-menu-2022__btn__bg'/>
                </button>
            }
            <Modal className='o-floating-menu-2022__content' open={showMenu} onClose={() => setShowMenu(false)}>
                <>
                    <button onClick={() => setShowMenu(false)} className='o-floating-menu-2022__btn' aria-label={showMenu ? 'Cerrar menú' : 'Abrir menú'}>
                        <img src={props.closeIcon} alt="" className='o-floating-menu-2022__btn__icon'/>
                        <img src={props.buttonBg} alt="" className='o-floating-menu-2022__btn__bg'/>
                    </button>
                    <img loading='lazy' src={props.menuBg} alt="" className='o-floating-menu-2022__content__bg'/>
                    <h2 className='o-floating-menu-2022__content__title'>{props.menuTitle}</h2>
                    <div className='o-floating-menu-2022__content__btns'>
                        <div className='o-floating-menu-2022__content__btns__container'>
                            <div className="o-floating-menu-2022__content__btns__container__left__left">
                                {
                                    menuData.categorias.map((elem, index) => (
                                        elem.referenciaSubcategorias[0].verEnMenu &&
                                            <div className='o-floating-menu-2022__content__btns__container__left__main' key={`${index}-main-left`}>
                                                <h3><a href={elem.hyperLink} rel='follow noreferrer'>{elem.nombreCategoria}</a></h3>
                                                {
                                                    elem.referenciaSubcategorias && elem.referenciaSubcategorias[0].referenciaSubcategorias
                                                    && elem.referenciaSubcategorias[0].referenciaSubcategorias.map((item, index) => (
                                                        item.verEnMenu &&
                                                        <div className='o-floating-menu-2022__content__btns__container__left__main__btn' key={`${index}-main1-item`}>
                                                            <i className='icon-chevron-right1'></i>
                                                            <a href={item.link}>{item.nombreSubcategoria}</a>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    ))
                                }
                            </div>
                            <div className="o-floating-menu-2022__content__btns__container__right">
                                <div className='o-floating-menu-2022__content__btns__container__right__main'>
                                    {
                                        menuData.categorias.map((elem, index) => (
                                            !elem.referenciaSubcategorias[0].verEnMenu &&
                                            <h3 key={`${index}-main-right`}><a href={elem.hyperLink} rel='follow noreferrer'>{elem.nombreCategoria}</a></h3>
                                        ))
                                    }
                                </div>
                                <div className='o-floating-menu-2022__content__btns__container__right__magenta'>
                                    {
                                        menuData.listaDerecha.map((elem, index) => (
                                            <div className='o-floating-menu-2022__content__btns__container__right__magenta__btn' key={`${index}-magenta`}>
                                                <i className='icon-chevron-right1'></i>
                                                <a href={elem.hyperLink} rel='follow noreferrer' target='_blank'>{elem.nombreCategoria}</a>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    )
}

export default FloatingMenuInforme2022
